<template>

  <validation-observer
    ref="formDataUtama"
  >
    <!-- profile info  -->
    <section id="tambah-surat-permohonan">
      <b-card
        header="Tambah Surat Permohonan"
        header-tag="h4"
        header-class="border-bottom mb-2"
      >

        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="mt-2"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div class="alert-body">
              <ul v-if="typeof alertDetail.text === 'object'">
                <li
                  v-for="(item, index) in alertDetail.text"
                  :key="index"
                >
                  {{ index }}:
                  <span
                    v-for="(child, indexs) in item"
                    :key="indexs"
                  >
                    {{ child }}
                  </span>
                </li>
              </ul>
              <span v-else>{{ alertDetail.text }}</span>
            </div>
          </b-alert>
        </template>

        <form @submit.prevent="submitForm">
          <b-row>
            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Nomor Surat"
                label-for="nomorSurat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor Surat"
                  rules="required"
                >
                  <b-form-input
                    id="nomorSurat"
                    v-model="form.letter_number"
                    name="letter_number"
                    placeholder="Nomor Surat"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Tahun Anggaran"
                label-for="vi-year_budget"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal Surat"
                  rules="required"
                >
                  <v-select
                    id="vi-year_budget"
                    v-model="form.year_budget"
                    required
                    name="year_budget"
                    :options="year_budget"
                    placeholder="Tahun Anggaran"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Perihal Surat"
                label-for="perihalSurat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Perihal Surat"
                  rules="required"
                >
                  <b-form-input
                    id="perihalSurat"
                    v-model="form.perihal"
                    name="perihal"
                    placeholder="Perihal Surat"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Tanggal Surat"
                label-for="tanggalSurat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal Surat"
                  rules="required"
                >
                  <b-form-datepicker
                    id="tanggalSurat"
                    v-model="form.tanggal_surat"
                    :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                    :max="today"
                    name="tanggal_surat"
                    placeholder="Tanggal Surat"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Jabatan Pengirim"
                label-for="nomorSurat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jabatan Pengirim"
                  rules="required"
                >
                  <b-form-input
                    id="nomorSurat"
                    v-model="form.jabatan"
                    name="jabatan"
                    placeholder="Jabatan Pengirim"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Jumlah Kegiatan"
                label-for="jumlahKegiatan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jumlah Kegiatan"
                  rules="required"
                >
                  <b-form-input
                    id="jumlahKegiatan"
                    v-model="form.total_program"
                    name="total_program"
                    type="number"
                    placeholder="Jumlah Kegiatan"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Nama Penanggung Jawab Permohonan"
                label-for="pic_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Penanggung Jawab Permohonan"
                  rules="required"
                >
                  <b-form-input
                    id="pic_name"
                    v-model="form.pic_name"
                    name="pic_name"
                    placeholder="Nama Penanggung Jawab Permohonan"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="No. Kontak Penanggung Jawab Permohonan"
                label-for="pic_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="No. Kontak Penanggung Jawab Permohonan"
                  rules="required"
                >
                  <b-form-input
                    id="pic_number"
                    v-model="form.pic_number"
                    name="pic_number"
                    type="number"
                    placeholder="No. Kontak Penanggung Jawab Permohonan"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Surat Permohonan Clearance (dalam format PDF)"
                label-for="letter_file"
              >
                <b-form-file
                  ref="letter_file"
                  v-model="file1"
                  name="letter_file"
                  class="mt-1"
                  required
                  @input="checkFile"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Peta Rencana Kegiatan (dalam format PDF)"
                label-for="blueprint"
              >
                <b-form-file
                  ref="blueprint"
                  v-model="file2"
                  name="blueprint"
                  class="mt-1"
                  required
                  @input="checkFile"
                />
              </b-form-group>
            </b-col>

            <b-col
              class="mb-1"
            >
              <b-button
                type="submit"
                variant="primary"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-card>
    </section>
  <!--/ profile info  -->

  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BButton, BFormInput, BRow, BCol, BCard, BFormGroup, BFormFile, BAlert, BFormDatepicker,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
    BFormFile,
    BAlert,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const date1 = new Date().getFullYear()

    return {
      date1,
      required,
      today,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      userData: getUserData(),
      form: {
        letter_number: '',
        total_program: '',
        tanggal_surat: '',
        jabatan: '',
        perihal: '',
        pic_name: '',
        pic_number: '',
        year_budget: localStorage.getItem('tahunAnggaran'),
      },
      clearanceData: '',
      clearanceId: '',
      file1: null,
      file2: null,
      message: '',
      // year_budget: [date1, date1 + 1],
      year_budget: [date1],
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role !== 100) {
      next('/')
    } else {
      next()
    }
  },
  mounted() {
    if (process.env.VUE_APP_MODE === 'dev') {
      this.year_budget = [this.date1, this.date1 + 1]
    }
  },
  methods: {
    checkFile(input) {
      if (input.size > 100 * 1024 * 1024) {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Ukuran Dokumen Terlalu Besar`,
        }
      } else if (input.type !== 'application/pdf') {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Jenis Dokumen Tidak Sesuai`,
        }
      } else {
        this.alert = false
      }
    },
    submitForm() {
      this.form.instansi_id = this.userData.instansi_id
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/clearance?token=${localStorage.getItem('userToken')}`, this.form)
        .then(res => {
          if (res.data && res.data.status === 'success') {
            localStorage.setItem('tahunAnggaran', this.form.year_budget)
            this.clearanceData = res.data.data.clearance
            this.clearanceId = res.data.data.clearance_id
            const status = true

            const promise1 = new Promise(resolve => {
              if (this.file1) {
                const formFile = new FormData()
                formFile.append('letter_file', this.file1)

                this.$http.post(`/clearance/upload/${this.clearanceId}?token=${localStorage.getItem('userToken')}`, formFile, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                  .then(resp => {
                    if (resp.data.status === 'success') {
                      resolve(true)
                    } else {
                      document.getElementById('loading-bg').style.display = 'none'
                      this.alert = true
                      this.alertDetail = {
                        variant: 'danger',
                        title: 'Error Found',
                        text: resp.data.error,
                      }
                      resolve(false)
                    }
                  })
                  .catch(error => {
                    document.getElementById('loading-bg').style.display = 'none'
                    this.alert = true
                    this.alertDetail = {
                      variant: 'danger',
                      title: 'Error Found',
                      text: error.response.data.error,
                    }
                    resolve(false)
                  })
              } else {
                resolve(true)
              }
            })

            const promise2 = new Promise(resolve => {
              if (this.file2) {
                const formFile = new FormData()
                formFile.append('blueprint', this.file2)

                this.$http.post(`/clearance/upload/${this.clearanceId}?token=${localStorage.getItem('userToken')}`, formFile, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                  .then(resp => {
                    if (resp.data.status === 'success') {
                      resolve(true)
                    } else {
                      document.getElementById('loading-bg').style.display = 'none'
                      this.alert = true
                      this.alertDetail = {
                        variant: 'danger',
                        title: 'Error Found',
                        text: resp.data.error,
                      }
                      resolve(false)
                    }
                  })
                  .catch(error => {
                    document.getElementById('loading-bg').style.display = 'none'
                    this.alert = true
                    this.alertDetail = {
                      variant: 'danger',
                      title: 'Error Found',
                      text: error.response.data.error,
                    }
                    resolve(false)
                  })
              } else {
                resolve(true)
              }
            })

            Promise.all([promise1, promise2, status]).then(values => {
              if (values[0] && values[1] && values[2]) {
                document.getElementById('loading-bg').style.display = 'none'
                this.$router.replace({ name: 'pengajuan-surat-detail', params: { id: this.clearanceId } })
              }
            })
              .catch(error => {
                document.getElementById('loading-bg').style.display = 'none'
                this.alert = true
                this.alertDetail = {
                  variant: 'danger',
                  title: 'Error Found',
                  text: error.response.data.error,
                }
              })
          } else {
            document.getElementById('loading-bg').style.display = 'none'
            this.alert = true
            this.alertDetail = {
              variant: 'danger',
              title: 'Error Found',
              text: res.data.error,
            }
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: error.response.data.error,
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
